<template>
  <main>
    <router-view class="view"></router-view>
  </main>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({

  data() {
    return { }
  }

})
</script>

<style lang="scss">

</style>
