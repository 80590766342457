export default {
  session: null,
  authToken: null,

  user: {},

  garments: [],
  dailies: [],

  currentWork: []
}
