<template>
  <main>
    profilepage
  </main>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({

  data() {
    return { }
  },

  computed: {
    slug() { return this.$route.params.slug }
  }

})
</script>

<style lang="scss">

</style>
